import { useEffect, useState } from "react";
import { useCookie } from "../utils/getCookie";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import Layout from "../components/Layout/Layout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import PartnershipPageLayout from "../components/PartnershipPage/PartnershipPageLayout/PartnershipPageLayout";

const partnershipPage = ({ pageContext }: any) => {
  const post = pageContext.data;
  const dataSEO = {
    metaTitle: post?.metaTitle,
    metaDescription: post?.metaDescription?.metaDescription || "",
    keywords: post?.metaTitle,
    preventIndexing: false,
    alternateHrefLink: true,
  };

  const [showCookiesConsent, setShowCookiesConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);

  const {
    footer_menu,
    header_menu,
    footer,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
  } = pageContext.globalSettings;
  return (
    <div>
      <Layout
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={true}
        page_background={true}
        dataSEO={dataSEO}
        countryPage={false}
        blog={false}
        title={{
          h1_title: post.h1Title,
          title: post.mainTitleStart,
          second_title: post.mainTitleEnd,
          title_color: "dark",
          second_title_color: "green",
          second_line: false,
          description: { description: post.mainTitleSubtext.mainTitleSubtext },
        }}
        url={pageContext.url}
        setModal={setShowModal}
        white={false}
      >
        <PartnershipPageLayout
          post={post}
          setModal={setShowModal}
        ></PartnershipPageLayout>
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
        {showModal && (
          <RegisterForm modal={showModal} setModal={setShowModal} />
        )}
      </Layout>
    </div>
  );
};

export default partnershipPage;
