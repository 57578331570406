import React from "react";
import * as styles from "./PartnershipPageLayout.module.scss";
import PartnershipPageLayoutI from "./PartnershipPageLayoutI.type";
import ImageWithTitle from "../ImageWithTitle/ImageWithTitle";
import IconCardRow from "../IconCardRow/IconCardRow";

const PartnershipPageLayout = ({ post, setModal }: PartnershipPageLayoutI) => {
  return (
    <div className={styles.partnershipPageLayout}>
      <ImageWithTitle
        image={post.section1Image}
        title={{
          h1_title: post.h1Title,
          title: post.section1H2TitleStart,
          second_title: post.section1H2TitleEnd,
          title_color: "dark",
          second_title_color: "blue",
          second_line: false,
          description: post.section1Subtext.section1Subtext,
        }}
        textPosition="left"
        background="white"
        firstModule={true}
      ></ImageWithTitle>
      <div id="threeIconCardRow" className={styles.threeIconCardRow}>
        <IconCardRow
          iconCards={post.section2IconCards}
          alignment="center"
          padding={true}
          bullets={true}
          radius={true}
        ></IconCardRow>
      </div>
      <div className={styles.sixIconCardRow}>
        <h2>{post.section3H2Title}</h2>
        <IconCardRow
          iconCards={post.section3IconCards}
          alignment="left"
          padding={false}
          bullets={false}
          radius={false}
        ></IconCardRow>
      </div>
      <ImageWithTitle
        image={post.section4CtaImage}
        title={{
          title: post.section4CtaH2TitleStart,
          second_title: post.section4CtaH2TitleEnd,
          title_color: "dark",
          second_title_color: "blue",
          second_line: false,
          description: post.section4CtaSubtext.section4CtaSubtext,
        }}
        textPosition="right"
        button={{
          text: post.section4CtaButtonText,
          size: "full",
          color: "blue",
          onClick: setModal,
        }}
        firstModule={false}
        background="grey"
        form={{
          formId: "69126587-d2bf-435e-8cd9-6f78ce0a0219",
          targetId: "hubspotForm",
        }}
      ></ImageWithTitle>
    </div>
  );
};

export default PartnershipPageLayout;
